import gql from 'graphql-tag';

export const ACCOUNTS_REPORTS = gql`query accountsReports($filter: Filters!){
  accountsReports(filter: $filter) {
    grandTotals {
      total {
        installs,
        newDownloads,
        redownloads,
        ttr,
        taps,
        impressions,
        localSpend {
          amount
        }
      }
    },
    row {
      total {
        localSpend {
          amount
        },
        avgCPA {
          amount
        },
        avgCPT {
          amount
        },
        impressions,
        taps,
        installs,
        ttr,
        conversionRate,
        newDownloads,
        redownloads,
      },
      orgMetadata {
        orgName,
        orgId
      }
    }
  }
}`;

export const APPS_REPORTS = gql`query appsReports($filter: Filters!){
                                        appsReports(filter: $filter) {
                                          grandTotals {
                                            total {
                                              installs,
                                              newDownloads,
                                              redownloads,
                                              ttr,
                                              taps,
                                              impressions,
                                              localSpend {
                                                amount
                                              }
                                            }
                                          },
                                          row {
                                            total {
                                              localSpend {
                                                amount
                                              },
                                              avgCPA {
                                                amount
                                              },
                                              avgCPT {
                                                amount
                                              },
                                              impressions,
                                              taps,
                                              installs,
                                              ttr,
                                              conversionRate,
                                              newDownloads,
                                              redownloads,
                                            },
                                            metadata {
                                              campaignStatus,
                                              displayStatus,
                                              app {
                                                adamId,
                                                appName
                                              },
                                              campaignId,
                                              orgId
                                            },
                                            orgMetadata {
                                              orgName
                                              orgId
                                            }
                                          }
                                        }
                                      }`;

export const CAMPAIGN_REPORTS = gql`query campaignReports($filter: Filters!) {
                                      campaignReports(filter: $filter) {
                                        grandTotals {
                                          total {
                                            installs,
                                            newDownloads,
                                            redownloads,
                                            ttr,
                                            taps,
                                            impressions,
                                            localSpend {
                                              amount
                                            }
                                          }
                                        },
                                        row {
                                          total {
                                            localSpend {
                                              amount
                                            },
                                            avgCPA {
                                              amount
                                            },
                                            avgCPT {
                                              amount
                                            },
                                            impressions,
                                            taps,
                                            installs,
                                            ttr,
                                            conversionRate,
                                            newDownloads,
                                            redownloads,
                                          },
                                          metadata {
                                            app {
                                              adamId,
                                              appName
                                            },
                                          },
                                          campaignMetadata {
                                            id,
                                            orgId,
                                            name,
                                            deleted,
                                            status,
                                            displayStatus,
                                            countriesOrRegions,
                                            budgetAmount {
                                              amount,
                                              currency
                                            },
                                            dailyBudgetAmount {
                                              amount,
                                              currency
                                            }
                                          },
                                          orgMetadata {
                                            orgId
                                            orgName
                                          }
                                        }
                                      }
                                    }`;

export const CAMPAIGN_GRAPH = gql`query campaignGraph($filter: GraphRequest!) {
  campaignChart: campaignGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const APPS_GRAPH = gql`query campaignGraph($filter: GraphRequest!) {
  appsChart: campaignGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const KEYWORDS_GRAPH = gql`query keywordsGraph($filter: GraphRequest!) {
  keywordsChart: keywordsGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const AD_GROUP_GRAPH = gql`query adGroupGraph($filter: GraphRequest!) {
  adGroupChart: adGroupGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const CREATIVE_SET_GRAPH = gql`query creativeSetGraph($filter: GraphRequest!) {
  creativeSetChart: creativeSetGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const SEARCH_TERMS_GRAPH = gql`query searchTermsGraph($filter: GraphRequest!) {
  searchTermsChart: searchTermsGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const ACCOUNTS_GRAPH = gql`query campaignGraph($filter: GraphRequest!) {
  accountsChart: campaignGraph(filter: $filter) {
    datasets {
      label,
      data
    },
    labels
  }
}`

export const SEARCH_TERMS_REPORTS = gql`query searchTermsReports($filter: Filters!) {
                                          searchTermsReports(filter: $filter) {
                                            grandTotals {
                                              total {
                                                installs,
                                                newDownloads,
                                                redownloads,
                                                ttr,
                                                taps,
                                                impressions,
                                                localSpend {
                                                  amount
                                                }
                                              }
                                            },
                                            row {
                                              total {
                                                localSpend {
                                                  amount
                                                },
                                                avgCPA {
                                                  amount
                                                },
                                                avgCPT {
                                                  amount
                                                },
                                                impressions,
                                                taps,
                                                installs,
                                                ttr,
                                                conversionRate,
                                                newDownloads,
                                                redownloads,
                                              },
                                              metadata {
                                                keywordId,
                                                keyword,
                                                matchType,
                                                bidAmount {
                                                  amount,
                                                  currency
                                                },
                                                deleted,
                                                keywordDisplayStatus,
                                                adGroupId,
                                                adGroupName,
                                                adGroupDeleted,
                                                searchTermText,
                                                countryOrRegion,
                                                searchTermSource,
                                              }
                                            }
                                          }
                                        }`;


export const CREATIVE_SET_REPORTS = gql`query creativeSetReports($filter: Filters!) {
                                          creativeSetReports(filter: $filter) {
                                            grandTotals {
                                              total {
                                                installs,
                                                newDownloads,
                                                redownloads,
                                                ttr,
                                                taps,
                                                impressions,
                                                localSpend {
                                                  amount
                                                }
                                              }
                                            },
                                            row {
                                              total {
                                                localSpend {
                                                  amount
                                                },
                                                avgCPA {
                                                  amount
                                                },
                                                avgCPT {
                                                  amount
                                                },
                                                impressions,
                                                taps,
                                                installs,
                                                ttr,
                                                conversionRate,
                                                newDownloads,
                                                redownloads,
                                              },
                                              metadata {
                                                creativeSetName,
                                                displayStatus,
                                              },
                                              creativeSetMetadata {
                                                 id,
                                                 creativeSetId,
                                                 name,
                                                 status,
                                                 campaignId,
                                                 adGroupId,
                                                 languageCode,
                                                 creativeSetId
                                               },
                                              adgroupMetadata {
                                                orgId,
                                                id,
                                                status
                                              },
                                              orgMetadata {
                                                orgName
                                              },
                                              campaignMetadata {
                                                adamId
                                                countriesOrRegions
                                                id
                                                status
                                                deleted
                                              }
                                            }
                                          }
                                        }`;



export const TARGETING_KEYWORD_REPORTS = gql`query targetingKeywordReports($filter: Filters!) {
                                              targetingKeywordReports(filter: $filter) {
                                                grandTotals {
                                                  total {
                                                    installs,
                                                    newDownloads,
                                                    redownloads,
                                                    ttr,
                                                    taps,
                                                    impressions,
                                                    localSpend {
                                                      amount
                                                    }
                                                  }
                                                },
                                                row {
                                                  total {
                                                    localSpend {
                                                      amount
                                                    },
                                                    avgCPA {
                                                      amount
                                                    },
                                                    avgCPT {
                                                      amount
                                                    },
                                                    impressions,
                                                    taps,
                                                    installs,
                                                    ttr,
                                                    conversionRate,
                                                    newDownloads,
                                                    redownloads,
                                                  },
                                                  metadata {
                                                    # campaignStatus,
                                                    # displayStatus,
                                                    # app {
                                                    #   adamId,
                                                    #   appName
                                                    # },
                                                    campaignId,
                                                    # orgId,
                                                    # campaignName,
                                                    # countriesOrRegions,
                                                    adGroupName
                                                  },
                                                  insights {
                                                    bidRecommendation {
                                                      bidMin {
                                                        amount
                                                        currency
                                                      },
                                                      bidMax {
                                                        amount
                                                        currency
                                                      }
                                                    }
                                                  },
                                                  campaignMetadata {
                                                    name,
                                                    adamId,
                                                    status,
                                                    countriesOrRegions,
                                                    deleted
                                                  },
                                                  adgroupMetadata {
                                                    campaignId,
                                                    orgId,
                                                    status
                                                  },
                                                  keywordMetadata {
                                                    id,
                                                    text,
                                                    status,
                                                    adGroupId,
                                                    bidAmount {
                                                      amount,
                                                      currency
                                                    }
                                                  }
                                                }
                                              }
                                            }`;



export const NEGATIVE_KEYWORD_REPORTS = gql`query negativeKeywordReports($filter: Filters!) {
                                              negativeKeywordReports(filter: $filter) {
                                                adGroupId
                                                campaignId
                                                deleted
                                                id
                                                matchType
                                                modificationTime
                                                status
                                                text
                                              }
                                            }`;


export const GET_INTEGRATION = gql`query {
                                        getIntegration {
                                          IntegrationID,
                                          type,
                                          name
                                        }
                                      }`


export const GET_CAMPAIGN_GROUPS = gql`query {
                                       getCampaignGroups{
                                        orgName,
                                        orgId,
                                        currency,
                                        timeZone,
                                        paymentModel,
                                        roleNames,
                                        IntegrationID
                                      }
                                     }`

export const GET_CAMPAIGNS_BY_ORG = gql`query getCampaignsByOrg($orgId: Float!) {
                                      getCampaignsByOrg(orgId: $orgId) {
                                        name,
                                        id,
                                        adamId,
                                        orgId,
                                        countriesOrRegions
                                      }
                                    }`

export const DELETE_INTEGRATION = gql`query {
                                        deleteIntegration
                                      }`

// TODO
export const GET_CAMPAIGN_DRAFT = gql`query getDraft($draftId: String!) {
                                        campaignDraft: getDraft(draftId: $draftId) {
                                          campaign {
                                          name,
                                          adamId,
                                          orgId,
                                          adChannelType,
                                          budgetAmount {
                                            currency,
                                            amount
                                          },
                                          countriesOrRegions,
                                          dailyBudgetAmount {
                                            currency,
                                            amount
                                          },
                                          budgetOrders,
                                          supplySources,
                                        },
                                        adGroup {
                                          name,
                                          orgId,
                                          status,
                                          startTime,
                                          endTime,
                                          defaultBidAmount {
                                            amount
                                          },
                                          cpaGoal {
                                            amount
                                          },
                                          automatedKeywordsOptIn,
                                          targetingDimensions {
                                            adminArea {
                                              included
                                            },
                                            age {
                                              included {
                                                minAge,
                                                maxAge
                                              }
                                            },
                                            appDownloaders {
                                              included,
                                              excluded
                                            },
                                            country {
                                              included
                                            },
                                            daypart {
                                              userTime {
                                                included
                                              }
                                            },
                                            deviceClass {
                                              included
                                            },
                                            gender {
                                              included
                                            },
                                            locality {
                                              included
                                            }
                                          }
                                        },
                                        targetKeywords {
                                          bidAmount {
                                            currency,
                                            amount
                                          },
                                          matchType,
                                          text
                                        },
                                        negativeKeywords {
                                          matchType,
                                          text
                                        }
                                        creativeSet {
                                          assetsGenIds,
                                          adamId,
                                          name,
                                          languageCode
                                        }
                                        step
                                        }
}`


export const AD_GROUP_REPORTS = gql`query adGroupReports($filter: Filters!) {
                                            adGroupReports(filter: $filter) {
                                              grandTotals {
                                                total {
                                                  installs,
                                                  newDownloads,
                                                  redownloads,
                                                  ttr,
                                                  taps,
                                                  impressions,
                                                  localSpend {
                                                    amount
                                                  }
                                                }
                                              },
                                              row {
                                                total {
                                                  localSpend {
                                                    amount
                                                  },
                                                  avgCPA {
                                                    amount
                                                  },
                                                  avgCPT {
                                                    amount
                                                  },
                                                  impressions,
                                                  taps,
                                                  installs,
                                                  ttr,
                                                  conversionRate,
                                                  newDownloads,
                                                  redownloads,
                                                },
                                                metadata {
                                                  # campaignStatus,
                                                  # displayStatus,
                                                  app {
                                                    adamId,
                                                    appName
                                                  },
                                                  campaignId,
                                                  orgId,
                                                  adGroupId
                                                  # campaignName,
                                                  # countriesOrRegions
                                                },
                                                orgMetadata {
                                                  orgName
                                                  orgId
                                                },
                                                campaignMetadata {
                                                  id,
                                                  name,
                                                  adamId,
                                                  status,
                                                  countriesOrRegions,
                                                  deleted
                                                },
                                                adgroupMetadata {
                                                  id,
                                                  name,
                                                  orgId,
                                                  campaignId,
                                                  status,
                                                  displayStatus,
                                                  startTime,
                                                  endTime,
                                                  automatedKeywordsOptIn,
                                                  defaultBidAmount {
                                                    currency,
                                                    amount
                                                  },
                                                  cpaGoal{
                                                    currency,
                                                    amount
                                                  },

                                                  targetingDimensions {
                                                    adminArea {
                                                      included
                                                    },
                                                    appDownloaders {
                                                      included,
                                                      excluded
                                                    },
                                                    country {
                                                      included
                                                    }
                                                    deviceClass {
                                                      included
                                                    },
                                                    gender {
                                                      included
                                                    },
                                                    locality {
                                                      included
                                                    },
                                                    daypart {
                                                      userTime {
                                                        included
                                                      }
                                                    },
                                                    age {
                                                      included {
                                                        minAge,
                                                        maxAge
                                                      }
                                                    },
                                                  }
                                                }
                                              }
                                            }
                                            }`;

export const APPSLIST_WITH_CAMPAIGNS = gql`
                                          query {
                                          	getAppsListWithCampaigns {
                                              adamId,
                                              appName,
                                              campaigns_exist
                                              adgroups_exist
                                              keywords_exist
                                              campaigns {
                                                id,
                                                orgId,
                                                name,
                                                adamId,
                                                countriesOrRegions,
                                                status
                                                adgroups_exist
                                                keywords_exist
                                                adgroups {
                                                  name,
                                                  id,
                                                  campaignId,
                                                  orgId,
                                                  defaultBidAmount {
                                                    currency,
                                                    amount
                                                  }
                                                  keywords_exist
                                                  keywords {
                                                    id,
                                                    text,
                                                    campaignId,
                                                    adGroupId
                                                  }
                                                }
                                              }
                                            }
                                          }`;


export const USER_DETAIL_BY_ID = gql`
                                    query findUserDetailById($securityPrincipleID: String!){
                                      findUserDetailById(securityPrincipleID: $securityPrincipleID) {
                                        FirstName,
                                        LastName,
                                        Phone,
                                        CompanyName,
                                        JobTitle,
                                        Role
                                      }
                                    }`;


export const MEMBER_DETAILS = gql`
                                  query findByTeamId {
                                    allMemberDetails: findByTeamId{
                                        FirstName,
                                        LastName,
                                        Phone,
                                        Email,
                                        CompanyName,
                                        JobTitle,
                                        InviteStatus,
                                        IsDisabled
                                    }
                                  }
                                  `;
export const APPS_BY_ORG = gql`
                                query getAppsByOrg($orgId: Float!) {
                                  apps: getAppsByOrg(orgId: $orgId) {
                                    adamId,
                                    appName
                                  }
                                }
                                `;

export const CHECK_CAMPAIGN_NAME = gql`
                                query checkCampaignName($name: String!) {
                                  checkCampaignName(name: $name) {
                                    doesCampaignExist
                                  }
                                }`

export const GET_OAUTH_URL = gql`
                            query getOAuthState($req: OAuthUrlReq!) {
                              getOAuthState(req: $req) {
                                url
                              }
                            }`

export const GET_CAMPAIGN_DRAFTS = gql`
                                query {
                                  getCampaignDrafts {
                                		_id,
                                    campaign {
                                      name,
                                      budgetAmount {
                                        amount
                                      },
                                      countriesOrRegions
                                    }
                                  }
                                }
`

export const ACCOUNTS_REPORTS_FOR_FILTER = gql`query accountsReports($filter: Filters!){
  accountsReportsForFilter: accountsReports(filter: $filter) {
    row {
      orgMetadata {
        orgName,
        orgId
      }
    }
  }
}`;

export const APPS_REPORTS_FOR_FILTER = gql`query appsReports($filter: Filters!){
  appsReportsForFilter: appsReports(filter: $filter) {
    row {
      metadata {
        app {
          adamId,
          appName
        }
      }
    }
  }
}`;

export const CAMPAIGN_REPORTS_FOR_FILTER = gql`query campaignReports($filter: Filters!) {
  campaignReportsForFilter: campaignReports(filter: $filter) {
    row {
      campaignMetadata {
        id,
        name
      },
    }
  }
}`;


export const AD_GROUP_REPORTS_FOR_FILTER = gql`query adGroupReports($filter: Filters!) {
  adgroupReportsForFilter: adGroupReports(filter: $filter) {
    row {
      adgroupMetadata {
        id,
        name
      }
    }
  }
}`;

export const APPS_WITH_ORG = gql`
                                query {
                                  getAppsWithOrg {
                                    orgName,
                                    orgId,
                                      appName,
                                      adamId,
                                  }
                                }`;
export const COUNTRIES_FOR_APP = gql`
                                query countriesForApp($adamId: Float!) {
                                  countriesForApp(adamId: $adamId) {
                                    adamId,
                                    countryOrRegionCodes,
                                    valuesForAutoCampaign {
                                      Code
                                      Name
                                      totalBudget
                                      dailyCap
                                      cpt
                                    }
                                  }
                                }
                                `;
